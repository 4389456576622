<template>
  <div class="font-inter min-h-screen antialiased text-white">
    <slot />
  </div>
</template>
<script setup lang="ts">
</script>

<style>
html, body {
  @apply bg-meclee-black-400;
}
</style>
